import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { firestore } from '../firebase';
import {
  collection,
  getDocs,
  addDoc,
  deleteDoc,
  query,
  where,
  doc,
  updateDoc,
} from 'firebase/firestore';
import {
  CommonButton,
  CommonEllipsisDropdown,
  CommonEllipsisButton,
  CommonEllipsisContent,
  CommonEllipsisItem,
  CommonContainer,
  CommonTitle,
  CommonInputContainer,
  CommonInput,
  CommonTable,
  CommonTableHeader,
  CommonTableCell,
  CommonBottomBar,
} from './CommonStyles';
import styled from 'styled-components';

// New styled component for the input wrapper
const SearchInputWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const ClearSearchButton = styled.span`
  position: absolute;
  right: 10px;
  cursor: pointer;
  color: #ccc;
`;

const StyledCommonInput = styled(CommonInput)`
  flex-grow: 1;
  padding-right: 30px; // Make room for the clear button
`;

const generateRandomColor = () => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

const ShoppingList = () => {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [items, setItems] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [tagColors, setTagColors] = useState({});
  const [sortField, setSortField] = useState('title');
  const [sortDirection, setSortDirection] = useState('asc');
  const [diningOutTotal, setDiningOutTotal] = useState(0);
  const [groceriesTotal, setGroceriesTotal] = useState(0);

  const itemsCollection = collection(firestore, 'items');

  const fetchTransactionsData = async () => {
    try {
      const currentMonth = new Date().getMonth();
      const currentYear = new Date().getFullYear();

      const transactionsQuery = query(
        collection(firestore, 'transactions'),
        where('date', '>=', new Date(currentYear, currentMonth, 1)),
        where('date', '<=', new Date(currentYear, currentMonth + 1, 0))
      );

      const transactionsSnapshot = await getDocs(transactionsQuery);
      const transactionsData = transactionsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      let diningOutTotal = 0;
      let groceriesTotal = 0;

      transactionsData.forEach((transaction) => {
        const category = transaction.category;
        const amount = parseFloat(transaction.amount);

        if (category === 'Dinning') {
          diningOutTotal += amount;
        } else if (category === 'Groceries') {
          groceriesTotal += amount;
        }
      });

      setDiningOutTotal(diningOutTotal);
      setGroceriesTotal(groceriesTotal);
    } catch (error) {
      console.error('Error fetching transactions data:', error.message);
    }
  };

  useEffect(() => {
    fetchItems();
    fetchTransactionsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchItems = useCallback(async () => {
    const itemsQuery = await getDocs(collection(firestore, 'items'));
    const itemsData = itemsQuery.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    setItems(itemsData);
  }, []);

  useEffect(() => {
    fetchItems();
  }, [fetchItems]);

  const filteredItems = searchQuery.trim() === '' ? items : items.filter(item =>
    item.tags?.some(tag => tag.toLowerCase().includes(searchQuery.toLowerCase())) ||
    item.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
    item.location.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const sortedItems = useMemo(() => {
    if (!sortField) {
      return filteredItems;
    }

    return [...filteredItems].sort((a, b) => {
      if (a[sortField] < b[sortField]) return sortDirection === 'asc' ? -1 : 1;
      if (a[sortField] > b[sortField]) return sortDirection === 'asc' ? 1 : -1;
      return 0;
    });
  }, [filteredItems, sortField, sortDirection]);

  useEffect(() => {
    const allTags = new Set(items.flatMap(item => item.tags || []));

    const newTagColors = { ...tagColors };
    let isUpdated = false;

    allTags.forEach(tag => {
      if (!newTagColors[tag]) {
        newTagColors[tag] = generateRandomColor();
        isUpdated = true;
      }
    });

    if (isUpdated) {
      setTagColors(newTagColors);
    }
  }, [items, tagColors]);

  const addItem = async () => {
    const title = window.prompt('Enter item title:', searchQuery);
    const location = window.prompt('Enter item location:');

    if (title && title.trim() !== '') {
      const newItem = { title: title.trim(), location: location.trim(), tags: [] };

      try {
        await addDoc(itemsCollection, newItem);
        fetchItems();
        setSearchQuery('');
      } catch (error) {
        console.error('Error adding item:', error);
      }
    } else {
      alert('Item title cannot be empty.');
    }
  };

  const editTags = async (itemId) => {
    const item = items.find(item => item.id === itemId);

    const currentTagsString = item && item.tags ? item.tags.join(',') : '';

    const input = prompt('Enter new tags (comma-separated)', currentTagsString);

    if (input !== null) {
      const newTags = input.split(',').map(tag => tag.trim()).filter(tag => tag !== '');
      await updateDoc(doc(itemsCollection, itemId), {
        tags: newTags,
      });
      fetchItems();
    }
    setIsDropdownVisible(false);
  };

  const removeItem = async (itemId) => {
    await deleteDoc(doc(itemsCollection, itemId));
    fetchItems();
    setIsDropdownVisible(false);
  };

  const editItem = async (itemId) => {
    const item = items.find(item => item.id === itemId);
    if (!item) return;

    const newTitle = prompt('Enter new title', item.title);
    if (newTitle === null || newTitle.trim() === '') {
      alert('Title cannot be empty.');
      return;
    }

    const newLocation = prompt('Enter new location (leave empty to remove location)', item.location);

    const updateData = { title: newTitle.trim() };
    if (newLocation !== null) {
      updateData.location = newLocation.trim();
    }

    await updateDoc(doc(itemsCollection, itemId), updateData);
    setIsDropdownVisible(false);
    fetchItems();
  };

  const applyTagFilter = (tag) => {
    setSearchQuery(tag);
  };

  const clearSearchQuery = () => {
    setSearchQuery('');
  };

  return (
    <CommonContainer>
      <CommonTitle>Shopping List</CommonTitle>
      <CommonInputContainer>
        <SearchInputWrapper>
          <StyledCommonInput
            type="text"
            placeholder="Search by any field or use 'Add' to insert a new item"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          {searchQuery && (
            <ClearSearchButton onClick={clearSearchQuery}>×</ClearSearchButton>
          )}
        </SearchInputWrapper>
        <CommonButton onClick={addItem}>Add Item</CommonButton>
      </CommonInputContainer>
      <CommonTable>
        <thead>
          <tr>
            <CommonTableHeader onClick={() => {
              setSortField('title');
              setSortDirection(prev => prev === 'asc' ? 'desc' : 'asc');
            }}>
              Title
            </CommonTableHeader>
            <CommonTableHeader onClick={() => {
              setSortField('location');
              setSortDirection(prev => prev === 'asc' ? 'desc' : 'asc');
            }}>
              Location
            </CommonTableHeader>
            <CommonTableHeader>Tags</CommonTableHeader>
            <CommonTableHeader>Actions</CommonTableHeader>
          </tr>
        </thead>
        <tbody>
          {sortedItems.map((item) => (
            <tr key={item.id}>
              <CommonTableCell>{item.title}</CommonTableCell>
              <CommonTableCell>{item.location}</CommonTableCell>
              <CommonTableCell>
                {item.tags.map((tag, index) => (
                  <span
                    key={index}
                    style={{
                      backgroundColor: tagColors[tag],
                      color: 'white',
                      padding: '3px 8px',
                      margin: '0 5px',
                      borderRadius: '5px',
                      cursor: 'pointer'
                    }}
                    onClick={() => applyTagFilter(tag)}
                  >
                    {tag}
                  </span>
                ))}
              </CommonTableCell>
              <CommonTableCell>
                <CommonEllipsisDropdown onMouseEnter={() => setIsDropdownVisible(true)} onMouseLeave={() => setIsDropdownVisible(false)}>
                  <CommonEllipsisButton>...</CommonEllipsisButton>
                  {isDropdownVisible && (
                    <CommonEllipsisContent>
                      <CommonEllipsisItem onClick={() => removeItem(item.id)}>Delete</CommonEllipsisItem>
                      <CommonEllipsisItem onClick={() => editItem(item.id)}>Edit</CommonEllipsisItem>
                      <CommonEllipsisItem onClick={() => editTags(item.id)}>Tags</CommonEllipsisItem>
                    </CommonEllipsisContent>
                  )}
                </CommonEllipsisDropdown>
              </CommonTableCell>
            </tr>
          ))}
        </tbody>
      </CommonTable>
      <CommonBottomBar>
        <div>
          Groceries: ${groceriesTotal.toFixed(2)} | Dining Out: ${diningOutTotal.toFixed(2)}
        </div>
      </CommonBottomBar>
    </CommonContainer>
  );
};

export default ShoppingList;
