import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import {
  collection,
  getDocs,
  addDoc,
  query,
  where,
  orderBy,
  updateDoc,
  doc
} from 'firebase/firestore';
import { firestore, auth } from '../firebase';
import styled from 'styled-components';
import {
  CommonContainer,
  CommonInput,
  CommonTitle,
  CommonButton,
  CommonTableCell,
  CommonBottomBar,
} from './CommonStyles';

const TableContainer = styled.div`
  caret-color: transparent;
  margin-top: 20px;
  overflow-x: auto;
  z-index: 0;
  display: block;
  max-width: 100%;
`;

const Table = styled.table`
  border-collapse: collapse;
  margin-top: 10px;
  width: auto;
  min-width: 100%;
`;

const TableHeader = styled.th`
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
  cursor: pointer;
  font-size: .7em;
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f9f9f9;
  }
`;

const TableCell = styled(CommonTableCell)`
  max-width: 110px;
`;

const StyledSelect = styled.select`
  padding: 5px;
  font-size: .7em;
  margin: 5px;
  max-width: 65px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media (min-width: 768px) {
    max-width: none;
  }
`;

const Transactions = () => {
  const [data, setData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sortColumn, setSortColumn] = useState('date');
  const [sortDirection, setSortDirection] = useState('desc');
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth());
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [searchQuery, setSearchQuery] = useState('');
  const [totalAmount, setTotalAmount] = useState(0);
  const [isEditing, setIsEditing] = useState(false);
  const [editAmount, setEditAmount] = useState('');
  const [showFullYear, setShowFullYear] = useState(false);
  const searchInputRef = useRef(null);
  const [searchOptions, setSearchOptions] = useState([]);
  const [refreshData, setRefreshData] = useState(false);

  const refreshDataCall = async () => {
    try {
      const user = auth.currentUser;
      const token = user && (await user.getIdToken());

      await axios.get('https://us-west1-chores-7bdc9.cloudfunctions.net/auth', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      setTimeout(async () => {
        setRefreshData()
      }, 5000);

    } catch (error) {
      console.error('Error fetching new data:', error.message);
    }
  };


  const formatDate = (timestamp) => {
    const date = timestamp.toDate();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  };

  const handlePrevMonth = () => {
    const newMonth = currentMonth === 0 ? 11 : currentMonth - 1;
    const newYear = currentMonth === 0 ? currentYear - 1 : currentYear;
    setCurrentMonth(newMonth);
    setCurrentYear(newYear);
  };

  const handleNextMonth = () => {
    const newMonth = currentMonth === 11 ? 0 : currentMonth + 1;
    const newYear = currentMonth === 11 ? currentYear + 1 : currentYear;
    setCurrentMonth(newMonth);
    setCurrentYear(newYear);
  };

  const handleShowFullYear = () => {
    setShowFullYear((prevShowFullYear) => !prevShowFullYear);
    setCurrentMonth(new Date().getMonth()); // Reset to current month when toggling
  };

  const handleKeyDown = async (itemId, e) => {
    if (e.key === 'Enter') {
      setIsEditing(null);
      await handleAmountEdit(itemId, editAmount);
    }
  };

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const categoriesSnapshot = await getDocs(collection(firestore, 'categories'));
        const categoriesData = categoriesSnapshot.docs.map((doc) => doc.data().name);
        const sortedCategoriesData = categoriesData.sort((a, b) => a.localeCompare(b));
        setSearchOptions([
          'Unknown', 'extra', 'food', 'bill', 'fun',
          ...sortedCategoriesData,
        ]);
      } catch (error) {
        console.error('Error fetching categories:', error.message);
      }
    };

    fetchCategories();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const categoriesSnapshot = await getDocs(collection(firestore, 'categories'));
        const categoriesData = categoriesSnapshot.docs.map((doc) => doc.data().name);
        const sortedCategoriesData = categoriesData.sort((a, b) => a.localeCompare(b));
        setCategories(sortedCategoriesData);

        let q;
        if (showFullYear) {
          const startOfYear = new Date(currentYear, 0, 1);
          const endOfYear = new Date(currentYear, 11, 31);
          q = query(
            collection(firestore, 'transactions'),
            where('date', '>=', startOfYear, '&&', 'date', '<=', endOfYear),
            orderBy(sortColumn, sortDirection)
          );
        } else {
          const startOfMonth = new Date(currentYear, currentMonth, 1);
          const endOfMonth = new Date(currentYear, currentMonth + 1, 0);
          q = query(
            collection(firestore, 'transactions'),
            where('date', '>=', startOfMonth, '&&', 'date', '<=', endOfMonth),
            orderBy(sortColumn, sortDirection)
          );
        }

        const querySnapshot = await getDocs(q);

        const newData = querySnapshot.docs
          .filter((doc) => {
            if (showFullYear) return true;
            const date = doc.data().date.toDate();
            return date.getMonth() === currentMonth && date.getFullYear() === currentYear;
          })
          .map((doc) => ({
            id: doc.id,
            ...doc.data(),
            date: formatDate(doc.data().date),
          }));

        setData(newData);
      } catch (error) {
        console.error('Error fetching data:', error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [sortDirection, sortColumn, currentMonth, currentYear, showFullYear, refreshData]);

  const handleCategoryChange = async (itemId, newCategory) => {
    try {
      const itemRef = doc(firestore, 'transactions', itemId);
      await updateDoc(itemRef, { category: newCategory });

      const updatedData = data.map((item) =>
        item.id === itemId ? { ...item, category: newCategory } : item
      );
      setData(updatedData);
    } catch (error) {
      console.error('Error updating category:', error.message);
    }
  };

  const handleSort = (column) => {
    setSortColumn(column);
    setSortDirection((prevDirection) => (prevDirection === 'asc' ? 'desc' : 'asc'));

    const sortedData = [...data].sort((a, b) => {
      const valueA = a[column] || '';
      const valueB = b[column] || '';

      if (sortDirection === 'asc') {
        return String(valueA).localeCompare(String(valueB), undefined, { numeric: true, sensitivity: 'base' });
      } else {
        return String(valueB).localeCompare(String(valueA), undefined, { numeric: true, sensitivity: 'base' });
      }
    });

    setData(sortedData);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleClearFilter = () => {
    setSearchQuery('');
  };

  const matchesCondition = (item, condition) => {
    const conditionLower = condition.toLowerCase();
    const isNegation = conditionLower.startsWith('!');
    const keyword = isNegation ? conditionLower.slice(1) : conditionLower;

    // Convert the categories array to lowercase for comparison
    const lowerCaseCategories = categories.map(category => category.toLowerCase());

    if (lowerCaseCategories.includes(keyword)) { // Check if the condition is a category name in lowercase
      // Perform comparison in lowercase to ensure case-insensitivity
      const itemCategoryLower = item.category ? item.category.toLowerCase() : '';
      return isNegation ? itemCategoryLower !== keyword : itemCategoryLower === keyword;
    }
    // Map the keyword to its corresponding logic
    switch (keyword) {
      case 'unknown':
        return !item.category || item.category.trim() === '';
      case 'fun':
        const matchesFun = Object.values(item).some(value =>
          typeof value === 'string' && (
            value.toLowerCase().includes('personal') ||
            value.toLowerCase().includes('out of band') ||
            value.toLowerCase().includes('car accessories')
          )
        );
        return isNegation ? !matchesFun : matchesFun;
      case 'extra':
        const matchesExtras = Object.values(item).some(
          (value) =>
            typeof value === 'string' &&
            (value.toLowerCase().includes('personal') || value.toLowerCase().includes('out of band') || value.toLowerCase().includes('car accessories') || value.toLowerCase().includes('home improvements'))
        );
        return isNegation ? !matchesExtras : matchesExtras;
      case 'food':
        const matchesFood = Object.values(item).some(value =>
          typeof value === 'string' && (
            value.toLowerCase().includes('groceries') ||
            value.toLowerCase().includes('dinning') ||
            value.toLowerCase().includes('beverages')
          )
        );
        return isNegation ? !matchesFood : matchesFood;
      case 'bill':
        const matchesBill = Object.values(item).some(value =>
          typeof value === 'string' && (
            value.toLowerCase().includes('taxes') ||
            value.toLowerCase().includes('internet & phone') ||
            value.toLowerCase().includes('mortgage') ||
            value.toLowerCase().includes('subscriptions') ||
            value.toLowerCase().includes('hoa') ||
            value.toLowerCase().includes('tithe') ||
            value.toLowerCase().includes('car payment') ||
            value.toLowerCase().includes('student loans') ||
            value.toLowerCase().includes('medical expenses') ||
            value.toLowerCase().includes('roof payment') ||
            value.toLowerCase().includes('insurance') ||
            value.toLowerCase().includes('utilities')
          )
        );
        return isNegation ? !matchesBill : matchesBill;
      // Handle other conditions as necessary
      default:
        return true; // If the condition keyword is not recognized, do not filter out the item by default
    }
  };

  const filteredData = data.filter((item) => {
    // Split the search query into conditions and trim whitespace
    const conditions = searchQuery.toLowerCase().split('&&').map(s => s.trim());
    // Check if the item matches all conditions
    return conditions.every(condition => matchesCondition(item, condition));
  });

  const handleSearchFocus = () => {
    setSearchQuery('');
    // Temporary workaround to trigger re-rendering of the datalist
    // by slightly modifying the searchOptions array.
    setSearchOptions(currentOptions => [...currentOptions]);
  };


  useEffect(() => {
    let total = 0;
    filteredData.forEach((item) => {
      total += parseFloat(item.amount);
    });
    setTotalAmount(total);
  }, [filteredData]);

  const handleAmountEdit = async (itemId, newAmount) => {
    try {
      const itemRef = doc(firestore, 'transactions', itemId);
      await updateDoc(itemRef, { amount: newAmount });

      const updatedData = data.map((item) =>
        item.id === itemId ? { ...item, amount: newAmount } : item
      );
      setData(updatedData);

      return Promise.resolve(); // Make sure to return a promise
    } catch (error) {
      console.error('Error updating amount:', error.message);
      return Promise.reject(error); // Make sure to return a rejected promise in case of error
    }
  };

  const handleEditClick = (itemId, amount) => {
    setIsEditing(itemId);
    setEditAmount(amount);
    setTimeout(() => {
      const input = document.getElementById(`amount-input-${itemId}`);
      if (input) {
        input.type = 'text'; // Change to text type
        input.focus();
        input.setSelectionRange(input.value.length, input.value.length);
        input.type = 'number'; // Change back to number type
      }
    }, 0);
  };

  const formatAmount = (amount) => {
    return `$${parseFloat(amount).toFixed(2)}`;
  };

  const handleCreateBillPrompt = async (transaction) => {
    const userConfirmed = window.confirm("Would you like to create a bill from this transaction?");
    if (userConfirmed) {
      await createBillFromTransaction(transaction);
    }
  };

  const createBillFromTransaction = async (transaction) => {
    try {
      // Extract the day from the transaction date
      const transactionDate = new Date(transaction.date);
      const transactionDay = transactionDate.getDate();

      // Create a new date object for the due date using the current year and month, but with the day set to 1 temporarily
      let dueDate = new Date(new Date().getFullYear(), new Date().getMonth(), 1);

      // Determine the last day of the current month
      const lastDayOfCurrentMonth = new Date(dueDate.getFullYear(), dueDate.getMonth() + 1, 0).getDate();

      // If the transaction day is greater than the last day of the current month, use the last day of the current month instead
      if (transactionDay > lastDayOfCurrentMonth) {
        dueDate.setDate(lastDayOfCurrentMonth);
      } else {
        dueDate.setDate(transactionDay);
      }

      // Format the due date as mm/dd/yyyy
      const formattedDueDate = (dueDate.getMonth() + 1).toString().padStart(2, '0') + '/' +
        dueDate.getDate().toString().padStart(2, '0') + '/' +
        dueDate.getFullYear();

      const billData = {
        amount: transaction.amount.toString(), // Ensure amount is a string
        dueDate: formattedDueDate,
        frequency: 'monthly',
        paid: false,
        status: 'active',
        title: transaction.location,
      };

      await addDoc(collection(firestore, 'bills'), billData);
    } catch (error) {
      console.error('Error creating bill:', error.message);
      alert('Failed to create bill.');
    }
  };

  const handleLocationEdit = async (itemId, newLocation) => {
    try {
      const itemRef = doc(firestore, 'transactions', itemId);
      await updateDoc(itemRef, { location: newLocation });

      const updatedData = data.map((item) =>
        item.id === itemId ? { ...item, location: newLocation } : item
      );
      setData(updatedData);
    } catch (error) {
      console.error('Error updating location:', error.message);
    }
  };

  return (
    <CommonContainer>
      <CommonTitle>Transactions</CommonTitle>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
        <CommonButton onClick={handlePrevMonth} disabled={showFullYear}>
          Previous Month
        </CommonButton>
        <CommonButton onClick={handleNextMonth} disabled={currentMonth === new Date().getMonth()}>
          Next Month
        </CommonButton>
        <CommonButton onClick={handleShowFullYear}>
          {showFullYear ? 'Show Current Month' : 'Show Full Calendar Year'}
        </CommonButton>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
        <CommonButton onClick={refreshDataCall}>Refresh Data</CommonButton>
        <CommonInput
          type="text"
          ref={searchInputRef}
          value={searchQuery}
          onChange={handleSearchChange}
          list="searchOptions"
          placeholder="Search transactions"
          style={{ marginLeft: 'auto' }}
          onFocus={handleSearchFocus}
        />
        <datalist id="searchOptions">
          {searchOptions.map((option) => (
            <option
              key={option}
              value={option}
              onClick={() => setSearchQuery('')} // Reset search query when option is clicked
            />
          ))}
        </datalist>
        <CommonButton onClick={handleClearFilter}>Clear Filter</CommonButton>
      </div>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <TableContainer>
          <Table>
            <thead>
              <tr>
                <TableHeader onClick={() => handleSort('date')}>Date</TableHeader>
                <TableHeader onClick={() => handleSort('location')}>Location</TableHeader>
                <TableHeader onClick={() => handleSort('category')}>Category</TableHeader>
                <TableHeader onClick={() => handleSort('amount')}>Amount</TableHeader>
              </tr>
            </thead>
            <tbody>
              {filteredData.map((item) => (
                <TableRow key={item.id}>
                  <TableCell onClick={() => handleCreateBillPrompt(item)}>{item.date}</TableCell>
                  <TableCell
                    onClick={() => {
                      const newLocation = prompt('Enter new location:', item.location);
                      if (newLocation !== null) {
                        handleLocationEdit(item.id, newLocation);
                      }
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    {item.location} - {item.user || 'Jeremiah'}
                  </TableCell>
                  <TableCell>
                    <StyledSelect
                      value={item.category || ''}
                      onChange={(e) => {
                        const newCategory = e.target.value;
                        handleCategoryChange(item.id, newCategory);
                      }}
                    >
                      <option value="" disabled>
                        Select a category
                      </option>
                      {categories.map((category) => (
                        <option key={category} value={category}>
                          {category}
                        </option>
                      ))}
                    </StyledSelect>
                  </TableCell>
                  <TableCell>
                    {isEditing === item.id ? (
                      <CommonInput
                        id={`amount-input-${item.id}`}
                        type="number"
                        value={editAmount}
                        onChange={(e) => setEditAmount(e.target.value)}
                        onKeyDown={(e) => handleKeyDown(item.id, e)}
                        onBlur={async () => {
                          setIsEditing(null);
                          await handleAmountEdit(item.id, editAmount);
                        }}
                        style={{ width: '60px' }}
                      />
                    ) : (
                      <div
                        onClick={() => handleEditClick(item.id, item.amount)}
                        style={{ cursor: 'pointer' }}
                      >
                        {formatAmount(item.amount)}
                      </div>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </tbody>
          </Table>
        </TableContainer>
      )}
      <CommonBottomBar>
        <div>Total: {formatAmount(totalAmount)}</div>
      </CommonBottomBar>
    </CommonContainer>
  );
};

export default Transactions;
