import React, { useState, useEffect } from 'react';
import {
  collection,
  getDocs,
  addDoc,
  updateDoc,
  doc,
  deleteDoc,
} from 'firebase/firestore';
import { firestore } from '../firebase';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import {
  CommonContainer,
  CommonTitle,
  CommonBottomBar,
  CommonButton,
  CommonInput,
  CommonLabel,
} from './CommonStyles';
import styled from 'styled-components';
import DatePickerInput from '../components/DatePickerInput';

const MealCard = styled.div`
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  margin: 10px;
  padding: 20px;
  cursor: pointer;
`;

const IngredientsList = styled.ul`
  list-style: none;
  padding: 0;
`;

const IngredientItem = styled.li`
  padding: 5px 0;
  display: flex;
  align-items: center;
`;

const MealPlan = () => {
  const [meals, setMeals] = useState([]);
  const [newMealName, setNewMealName] = useState('');
  const [ingredients, setIngredients] = useState([{ name: '', checked: false }]);

  const fetchMeals = async () => {
    const querySnapshot = await getDocs(collection(firestore, 'meal-plan'));
    let mealsData = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
      date: doc.data().date ? new Date(doc.data().date.seconds * 1000) : null,
      isVisible: false
    }));

    // Sorting meals by date if defined, otherwise by name
    mealsData.sort((a, b) => {
      if (a.date && b.date) {
        return a.date - b.date; // Sort by date if both have dates
      } else if (a.date) {
        return -1; // Meals with dates come first
      } else if (b.date) {
        return 1; // Meals with dates come first
      } else {
        return a.name.localeCompare(b.name); // If neither has a date, sort by name
      }
    });

    setMeals(mealsData);
  };

  useEffect(() => {
    fetchMeals();
  }, []);

  const handleAddIngredient = () => {
    setIngredients([...ingredients, { name: '', checked: false }]);
  };

  const handleRemoveIngredient = index => {
    setIngredients(ingredients.filter((_, idx) => idx !== index));
  };

  const handleIngredientNameChange = (index, value) => {
    const newIngredients = ingredients.map((ingredient, idx) => {
      if (idx === index) {
        return { ...ingredient, name: value };
      }
      return ingredient;
    });
    setIngredients(newIngredients);
  };

  const handleAddMeal = async () => {
    const newMeal = {
      name: newMealName,
      ingredients,
    };

    try {
      const docRef = await addDoc(collection(firestore, 'meal-plan'), newMeal);
      setMeals([...meals, { ...newMeal, id: docRef.id, isVisible: false }]);
      setNewMealName('');
      setIngredients([{ name: '', checked: false }]);
    } catch (error) {
      console.error("Error adding document: ", error);
    }
  };

  const toggleIngredientsVisibility = (mealId) => {
    const updatedMeals = meals.map(meal => {
      if (meal.id === mealId) {
        return { ...meal, isVisible: !meal.isVisible };
      }
      return meal;
    });
    setMeals(updatedMeals);
  };

  const handleIngredientCheck = async (mealId, index) => {
    const updatedMeals = meals.map(meal => {
      if (meal.id === mealId) {
        const updatedIngredients = meal.ingredients.map((ingredient, idx) => {
          if (idx === index) {
            return { ...ingredient, checked: !ingredient.checked };
          }
          return ingredient;
        });
        return { ...meal, ingredients: updatedIngredients };
      }
      return meal;
    });
    setMeals(updatedMeals);

    const mealDocRef = doc(firestore, 'meal-plan', mealId);
    const updatedMeal = updatedMeals.find(meal => meal.id === mealId);
    try {
      await updateDoc(mealDocRef, {
        ingredients: updatedMeal.ingredients,
      });
    } catch (error) {
      console.error('Error updating ingredients:', error);
    }
  };

  const handleSetDate = async (mealId, date) => {
    const mealDocRef = doc(firestore, 'meal-plan', mealId);
    try {
      await updateDoc(mealDocRef, {
        date: date
      });
      fetchMeals();
    } catch (error) {
      console.error('Error setting date:', error);
    }
  };

  const handleDeleteMeal = async (mealId) => {
    // Add a confirmation dialog
    if (window.confirm("Are you sure you want to delete this meal?")) {
      try {
        await deleteDoc(doc(firestore, 'meal-plan', mealId));
        setMeals(meals.filter(meal => meal.id !== mealId));
      } catch (error) {
        console.error('Error deleting meal:', error);
      }
    } else {
      console.log("Delete action canceled");
    }
  };

  const handleClearDate = async (mealId) => {
    const mealDocRef = doc(firestore, 'meal-plan', mealId);
    try {
      await updateDoc(mealDocRef, {
        date: null
      });
      fetchMeals();
    } catch (error) {
      console.error('Error clearing date:', error);
    }
  };

  const addToShoppingList = async (mealId) => {
    const mealIndex = meals.findIndex(meal => meal.id === mealId);
    if (mealIndex === -1) return;

    const meal = meals[mealIndex];
    const itemsCollection = collection(firestore, 'items');
    const checkedIngredients = meal.ingredients.filter(ingredient => ingredient.checked);

    try {
      for (let ingredient of checkedIngredients) {
        await addDoc(itemsCollection, {
          title: ingredient.name,
          location: "",
          tags: []
        });
      }

      // Reset the checked status of all ingredients
      const updatedIngredients = meal.ingredients.map(ingredient => ({
        ...ingredient,
        checked: false
      }));

      // Update the meal in Firestore
      const mealDocRef = doc(firestore, 'meal-plan', mealId);
      await updateDoc(mealDocRef, {
        ingredients: updatedIngredients
      });

      // Update the local state
      const updatedMeals = [...meals];
      updatedMeals[mealIndex] = {
        ...meal,
        ingredients: updatedIngredients
      };
      setMeals(updatedMeals);

    } catch (error) {
      console.error('Error adding ingredients to shopping list:', error);
    }
  };

  return (
    <CommonContainer>
      <CommonTitle>Meal Plan</CommonTitle>
      <div>
        <CommonLabel>Name of Meal:</CommonLabel>
        <CommonInput
          value={newMealName}
          onChange={(e) => setNewMealName(e.target.value)}
          placeholder="Enter meal name"
        />
        {ingredients.map((ingredient, index) => (
          <div key={index}>
            <CommonInput
              value={ingredient.name}
              onChange={(e) => handleIngredientNameChange(index, e.target.value)}
              placeholder="Enter ingredient"
            />
            <CommonButton onClick={() => handleRemoveIngredient(index)}>Remove</CommonButton>
          </div>
        ))}
        <CommonButton onClick={handleAddIngredient}>Add Ingredient</CommonButton>
        <CommonButton onClick={handleAddMeal}>Add Meal</CommonButton>
      </div>
      {meals.map(meal => (
        <MealCard key={meal.id} onClick={() => toggleIngredientsVisibility(meal.id)}>
          <h3>
            {meal.name} - {meal.date ? meal.date.toDateString() : 'No Date Set'}
          </h3>
          {meal.isVisible && (
            <div>
              <IngredientsList>
                {meal.ingredients.map((ingredient, index) => (
                  <IngredientItem key={index}>
                    <input
                      type="checkbox"
                      checked={ingredient.checked}
                      onChange={() => handleIngredientCheck(meal.id, index)}
                    />
                    {ingredient.name}
                  </IngredientItem>
                ))}
              </IngredientsList>
              <CommonButton onClick={() => handleDeleteMeal(meal.id)}>Delete Meal</CommonButton>
              {meal.date && <CommonButton onClick={() => handleClearDate(meal.id)}>Clear Date</CommonButton>}
              <DatePicker
                selected={meal.date}
                onChange={(date) => handleSetDate(meal.id, date)}
                dateFormat="MMMM d, yyyy"
                placeholderText="Add Date"
                customInput={<DatePickerInput />}
              />
              {meal.ingredients.some(ingredient => ingredient.checked) && (
                <CommonButton onClick={() => addToShoppingList(meal.id)}>Add to Shopping List</CommonButton>
              )}
            </div>
          )}
        </MealCard>
      ))}
      <CommonBottomBar />
    </CommonContainer>
  );
};

export default MealPlan;
