import React, { useEffect, useState } from 'react';
import {
  collection,
  query,
  where,
  orderBy,
  getDocs,
} from 'firebase/firestore';
import { firestore } from '../firebase';
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid } from 'recharts';
import {
  CommonContainer,
  CommonTitle,
  CommonBottomBar
} from './CommonStyles';
import { useMediaQuery } from 'react-responsive';

const MoneySummary = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [monthlyData, setMonthlyData] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [totalSpending, setTotalSpending] = useState(0);

  useEffect(() => {
    const fetchMonthlyData = async () => {
      try {
        const today = new Date();
        const monthsAgo = isMobile ? 5 : 11;
        const startDate = new Date(today.getFullYear(), today.getMonth() - monthsAgo, 1);

        const q = query(
          collection(firestore, 'transactions'),
          where('date', '>=', startDate),
          orderBy('date', 'asc')
        );

        const querySnapshot = await getDocs(q);

        const groupedData = {};
        querySnapshot.forEach((doc) => {
          const transaction = doc.data();
          const amount = parseFloat(transaction.amount);
          const category = transaction.category || 'Uncategorized';
          const monthYear = `${new Intl.DateTimeFormat('en-US', { month: 'long' }).format(transaction.date.toDate())} ${transaction.date.toDate().getFullYear()}`;

          if (!groupedData[monthYear]) {
            groupedData[monthYear] = {};
          }

          if (!groupedData[monthYear]['All']) {
            groupedData[monthYear]['All'] = 0;
          }

          groupedData[monthYear]['All'] += amount;

          if (!groupedData[monthYear][category]) {
            groupedData[monthYear][category] = 0;
          }

          groupedData[monthYear][category] += amount;
        });

        const formattedData = Object.entries(groupedData).flatMap(([monthYear, categoryData]) =>
          Object.entries(categoryData).map(([category, totalSpending]) => ({
            category,
            monthYear,
            totalSpending,
          }))
        );

        setMonthlyData(formattedData);
      } catch (error) {
        console.error('Error fetching monthly data:', error);
      }
    };

    fetchMonthlyData();
  }, [isMobile]);

  const filteredData =
    selectedCategory === 'All'
      ? monthlyData.filter((item) => item.category === 'All')
      : monthlyData.filter((item) => item.category === selectedCategory);

  useEffect(() => {
    const total = filteredData.reduce((acc, curr) => acc + curr.totalSpending, 0);
    setTotalSpending(total);
    // eslint-disable-next-line no-use-before-define
  }, [filteredData]);

  return (
    <CommonContainer>
      <CommonTitle>
        Money Summary - Last {isMobile ? '6' : '12'} Months
      </CommonTitle>
      {monthlyData.length > 0 && (
        <ResponsiveContainer width="100%" height={400}>
          <select value={selectedCategory} onChange={(e) => setSelectedCategory(e.target.value)}>
            <option value="All">All Categories</option>
            {Array.from(new Set(monthlyData.map((item) => item.category))).filter((category) => category !== 'All').map((category) => (
              <option key={category} value={category}>
                {category}
              </option>
            ))}
          </select>
          <BarChart
            data={filteredData}
            margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
          >
            <XAxis dataKey="monthYear" />
            <YAxis />
            <Tooltip />
            <Bar dataKey="totalSpending" fill="#8884d8" label={false} />
            <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
          </BarChart>
        </ResponsiveContainer>
      )}
      <CommonBottomBar>
        <div>Total Spent: ${totalSpending.toFixed(2)}</div>
      </CommonBottomBar>
    </CommonContainer>
  );
};

export default MoneySummary;
