import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { doc, getDoc } from 'firebase/firestore';
import { firestore } from '../firebase'; // Adjust the import path as necessary
import { CommonInputContainer, CommonContainer, CommonTitle, StyledDatePicker } from './CommonStyles';

const LocationTimeline = () => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [locations, setLocations] = useState([]);

  useEffect(() => {
    const fetchLocations = async () => {
      const formattedDate = selectedDate.toISOString().split('T')[0]; // Format date as YYYY-MM-DD
      const docRef = doc(firestore, 'locations', formattedDate);
      try {
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setLocations(docSnap.data().locations); // Assuming 'locations' is the array of location strings
        } else {
          setLocations([]); // No data found for the selected date
          console.log("No data available for this date.");
        }
      } catch (error) {
        console.error('Failed to fetch locations:', error);
        setLocations([]); // Reset locations on error
      }
    };

    fetchLocations();
  }, [selectedDate]);

  return (
    <CommonContainer>
      <CommonTitle>Timeline</CommonTitle>
      <CommonInputContainer>
        <StyledDatePicker>
          <DatePicker
            selected={selectedDate}
            onChange={date => setSelectedDate(date)}
            dateFormat="yyyy/MM/dd"
            inline
          />
        </StyledDatePicker>
        <div>
          {locations.length > 0 ? (
            <ul>
              {locations.map((location, index) => (
                <li key={index}>{location}</li>
              ))}
            </ul>
          ) : (
            <p>No locations found for selected date.</p>
          )}
        </div>
      </CommonInputContainer>
    </CommonContainer>
  );
};

export default LocationTimeline;
