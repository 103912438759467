import React, { useEffect, useState } from 'react';
import { firestore } from '../firebase';
import { collection, getDocs, updateDoc, setDoc, doc, deleteDoc, deleteField } from 'firebase/firestore';
import { CommonContainer, CommonTitle } from './CommonStyles';
import styled from 'styled-components';

const ListContainer = styled.div`
  cursor: pointer;
  padding: 10px;
  border: 1px solid #ccc;
  margin-bottom: 5px;
  background-color: ${props => props.isActive ? '#e9e9e9' : 'white'};
  position: relative;
`;

const ListTitle = styled.h2`
  margin: 0;
  font-size: 1.2em;
`;

const ListItems = styled.ul`
  display: ${props => props.isActive ? 'block' : 'none'};
  padding-left: 20px;
`;

const DeleteButton = styled.span`
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
  color: red;
  &:hover {
    color: darkred;
  }
`;

const ItemDeleteButton = styled.span`
  cursor: pointer;
  color: red;
  margin-left: 10px;
  &:hover {
    color: darkred;
  }
`;

const Notes = () => {
  const [lists, setLists] = useState([]);
  const [activeList, setActiveList] = useState(null);
  const [newListName, setNewListName] = useState('');
  const [newSectionName, setNewSectionName] = useState('');
  const [newItemName, setNewItemName] = useState('');
  const [selectedSection, setSelectedSection] = useState('');
  const [isCheckbox, setIsCheckbox] = useState(false);

  const fetchLists = async () => {
    const listsCollectionRef = collection(firestore, 'lists');
    const querySnapshot = await getDocs(listsCollectionRef);
    let fetchedLists = [];
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      const sortedKeys = Object.keys(data).filter(key => key !== 'id').sort();
      const sortedData = { id: doc.id };

      sortedKeys.forEach((key) => {
        if (Array.isArray(data[key])) {
          sortedData[key] = data[key].sort((a, b) => {
            const nameA = a && a.name ? a.name.toUpperCase() : '';
            const nameB = b && b.name ? b.name.toUpperCase() : '';
            return nameA.localeCompare(nameB);
          });
        } else {
          sortedData[key] = data[key];
        }
      });

      fetchedLists.push(sortedData);
    });

    fetchedLists.sort((a, b) => a.id.localeCompare(b.id));
    setLists(fetchedLists);
  };

  useEffect(() => {
    fetchLists();
  }, []);

  const handleCheckboxChange = async (listId, key, itemIndex) => {
    const newList = lists.map(list => {
      if (list.id === listId) {
        const newItems = list[key].map((item, index) => {
          if (index === itemIndex) {
            return { ...item, checked: !item.checked };
          }
          return item;
        });
        return { ...list, [key]: newItems };
      }
      return list;
    });

    setLists(newList);
    const listRef = doc(firestore, 'lists', listId);

    try {
      await updateDoc(listRef, {
        [key]: newList.find(list => list.id === listId)[key]
      });
    } catch (error) {
      console.error("Error updating document: ", error);
    }
  };

  const handleListClick = (id) => {
    setActiveList(activeList === id ? null : id);
  };

  const renderListItem = (item, index, listId, key) => {
    if (typeof item === 'object' && item !== null && 'checked' in item) {
      return (
        <li key={index}>
          <input
            type="checkbox"
            checked={item.checked}
            onChange={() => handleCheckboxChange(listId, key, index)}
          />
          {item.name}
          <ItemDeleteButton onClick={() => handleDeleteItem(listId, key, index)}>X</ItemDeleteButton>
        </li>
      );
    } else {
      return (
        <li key={index}>
          {item}
          <ItemDeleteButton onClick={() => handleDeleteItem(listId, key, index)}>X</ItemDeleteButton>
        </li>
      );
    }
  };

  const addNewList = async () => {
    const newList = {
      id: newListName,
    };

    if (!newListName.trim()) {
      console.error("List name is required");
      return;
    }

    try {
      const listRef = doc(firestore, "lists", newListName);
      await setDoc(listRef, newList);
      fetchLists();
      setNewListName('');
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };

  const handleDelete = async (event, listId) => {
    event.stopPropagation();
    if (window.confirm("Are you sure you want to delete this list?")) {
      try {
        const listRef = doc(firestore, 'lists', listId);
        await deleteDoc(listRef);
        setLists(lists.filter(list => list.id !== listId));
        console.log("List successfully deleted");
      } catch (error) {
        console.error("Error removing document: ", error);
      }
    } else {
      console.log("Delete action canceled");
    }
  };

  const handleDeleteItem = async (listId, key, itemIndex) => {
    const newList = lists.map(list => {
      if (list.id === listId) {
        const newItems = list[key].filter((item, index) => index !== itemIndex);
        return { ...list, [key]: newItems };
      }
      return list;
    });

    setLists(newList);
    const listRef = doc(firestore, 'lists', listId);

    try {
      await updateDoc(listRef, {
        [key]: newList.find(list => list.id === listId)[key]
      });
    } catch (error) {
      console.error("Error updating document: ", error);
    }
  };

  const handleDeleteSection = async (listId, sectionName) => {
    const newList = lists.map(list => {
      if (list.id === listId) {
        const { [sectionName]: _, ...rest } = list; // Remove the section from the list
        return rest;
      }
      return list;
    });

    setLists(newList);
    const listRef = doc(firestore, 'lists', listId);

    try {
      await updateDoc(listRef, {
        [sectionName]: deleteField() // Use deleteField to remove the section from Firestore
      });
    } catch (error) {
      console.error("Error updating document: ", error);
    }
  };

  const addNewSection = async () => {
    if (!newSectionName.trim() || !activeList) {
      console.error("Section name and active list are required");
      return;
    }

    const newList = lists.map(list => {
      if (list.id === activeList) {
        return { ...list, [newSectionName]: [] };
      }
      return list;
    });

    setLists(newList);
    const listRef = doc(firestore, 'lists', activeList);

    try {
      await updateDoc(listRef, {
        [newSectionName]: []
      });
      setNewSectionName('');
    } catch (error) {
      console.error("Error updating document: ", error);
    }
  };

  const addNewItem = async () => {
    if (!newItemName.trim() || !selectedSection || !activeList) {
      console.error("Item name, section, and active list are required");
      return;
    }

    const newItem = isCheckbox ? { name: newItemName, checked: false } : newItemName;

    const newList = lists.map(list => {
      if (list.id === activeList) {
        return {
          ...list,
          [selectedSection]: [...list[selectedSection], newItem]
        };
      }
      return list;
    });

    setLists(newList);
    const listRef = doc(firestore, 'lists', activeList);

    try {
      await updateDoc(listRef, {
        [selectedSection]: newList.find(list => list.id === activeList)[selectedSection]
      });
      setNewItemName('');
      setIsCheckbox(false);
    } catch (error) {
      console.error("Error updating document: ", error);
    }
  };

  return (
    <CommonContainer>
      <CommonTitle>Lists</CommonTitle>
      <input
        type="text"
        placeholder="Enter List Name"
        value={newListName}
        onChange={(e) => setNewListName(e.target.value)}
      />
      <button onClick={addNewList}>Add New List</button>
      {lists.map((list) => (
        <div key={list.id}>
          <ListContainer
            isActive={activeList === list.id}
            onClick={() => handleListClick(list.id)}
          >
            <ListTitle>{list.id}</ListTitle>
            <DeleteButton onClick={(e) => handleDelete(e, list.id)}>
              X
            </DeleteButton>
          </ListContainer>



          <ListItems isActive={activeList === list.id}>
            <div>
              <input
                type="text"
                placeholder="Enter Section Name"
                value={newSectionName}
                onChange={(e) => setNewSectionName(e.target.value)}
              />
              <button onClick={addNewSection}>Add New Section</button>
            </div>
            <div>
              <select
                value={selectedSection}
                onChange={(e) => setSelectedSection(e.target.value)}
              >
                <option value="">Select Section</option>
                {Object.keys(list).filter(key => key !== 'id').map(key => (
                  <option key={key} value={key}>{key}</option>
                ))}
              </select>
              <input
                type="text"
                placeholder="Enter Item Name"
                value={newItemName}
                onChange={(e) => setNewItemName(e.target.value)}
              />
              <label>
                <input
                  type="checkbox"
                  checked={isCheckbox}
                  onChange={(e) => setIsCheckbox(e.target.checked)}
                />
                Checkbox Item
              </label>
              <button onClick={addNewItem}>Add New Item</button>
            </div>

            {Object.entries(list).map(([key, value]) => {
              if (key !== 'id' && Array.isArray(value)) {
                return (
                  <div key={key}>
                    <h3>
                      {key}
                      <ItemDeleteButton onClick={() => handleDeleteSection(list.id, key)}>X</ItemDeleteButton>
                    </h3>
                    <ul>
                      {value.map((item, index) => renderListItem(item, index, list.id, key))}
                    </ul>
                  </div>
                );
              }
              return null;
            })}

          </ListItems>
        </div>
      ))}
    </CommonContainer>
  );
}

export default Notes;
