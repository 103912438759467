import styled from 'styled-components';

export const CommonContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 30px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    margin-top: 20px;
    font-size: 14px;
    padding: 5px;
  }
`;

export const StyledDatePicker = styled.div`
  .react-datepicker {
    width: 100%;
    max-width: 242px;
    margin: 0 auto;
  }

  .react-datepicker__month-container {
    background-color: #fff;
    border: 1px solid #ccc;
  }

  .react-datepicker__header {
    background-color: #f8f9fa;
    border-bottom: 1px solid #ccc;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--keyboard-selected {
    background-color: #007bff;
    color: #fff;
  }

  .react-datepicker__day--today {
    font-weight: bold;
  }
`;

export const CommonBottomBar = styled.div`
  caret-color: transparent;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #f0f0f0;
  padding: 11px;
  border-top: 1px solid #ddd;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  z-index: 999;
  min-height: 15px;
  font-size: 14px;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
  }
`;

export const CommonLabel = styled.label`
  margin-bottom: 5px;
  display: block;
  color: #333; // Choose a color that fits your theme
  font-size: 16px; // Set the font size as needed
  font-weight: bold; // Make labels bold to stand out
  padding: 5px 0; // Add some padding for spacing
`;

export const CommonTitle = styled.h2`
  color: #fff;
  background-color: #DC143C;
  padding: 10px;
  text-align: center;
  caret-color: transparent;
  cursor: default;
`;

export const CommonInputContainer = styled.div`
  margin-bottom: 15px;
  display: flex;
  gap: 10px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const CommonInput = styled.input`
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  @media (min-width: 768px) {
    width: auto;
  }
`;

export const CommonButton = styled.button`
  padding: 8px;
  background-color: ${(props) => (props.disabled ? '#ccc' : '#4caf50')};
  color: white;
  border: none;
  border-radius: 4px;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};

  &:hover {
    background-color: ${(props) => (props.disabled ? '#ccc' : '#45a049')};
  }
`;

export const CommonTagInput = styled.input`
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

export const CommonEllipsisDropdown = styled.div`
  position: relative;
  display: inline-block;
`;

export const CommonEllipsisButton = styled.button`
  caret-color: transparent;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px;
  cursor: pointer;
`;

export const CommonEllipsisContent = styled.div`
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;

  ${CommonEllipsisDropdown}:hover & {
    display: block;
  }
`;

export const CommonEllipsisItem = styled.button`
  padding: 8px;
  width: 100%;
  text-align: left;
  border: none;
  background-color: inherit;
  cursor: pointer;

  &:hover {
    background-color: #ddd;
  }
`;

export const CommonSummaryText = styled.p`
  font-size: 16px;
  margin: 5px 0;
`;

export const CommonTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
`;

export const CommonTableHeader = styled.th`
  padding: 10px;
  background-color: #4caf50;
  font-size: 16px;
  color: white;
  text-align: left;
  caret-color: transparent;
  white-space: nowrap;
  max-width: 90px;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: 768px) {
    ${(props) => props.mobileHidden && 'display: none;'}
  }
`;

export const CommonTableCell = styled.td`
  padding: 10px;
  border: 1px solid #ddd;
  font-size: .8em;
  caret-color: transparent;
  cursor: default;

  @media (max-width: 768px) {
    ${(props) => props.mobileHidden && 'display: none;'}
  }
`;

export const CommonTableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f9f9f9;
  }
`;
