import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { CommonButton } from '../pages/CommonStyles';

const CustomDateButton = styled(CommonButton)`
  margin-top: 10px;
`;

const DatePickerInput = ({ value, onClick }) => (
  <CustomDateButton onClick={(e) => {
    e.stopPropagation(); // Stop the event from propagating to the MealCard
    onClick(e);
  }}>
    {value || "Add Date"}
  </CustomDateButton>
);

// Define prop types for DatePickerInput component
DatePickerInput.propTypes = {
  value: PropTypes.string,
  onClick: PropTypes.func.isRequired
};

export default DatePickerInput;
