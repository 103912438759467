// BillsList.js

import React, { useEffect, useState } from 'react';
import { collection, getDocs, query, orderBy, updateDoc, doc, where, deleteDoc } from 'firebase/firestore';
import { firestore } from '../firebase';
import { styled, css } from 'styled-components';
import { PieChart, Pie, Tooltip, Legend, Cell } from 'recharts';

import {
  CommonContainer,
  CommonTitle,
  CommonSummaryText,
  CommonTableCell,
  CommonTable,
  CommonBottomBar,
  CommonTableHeader,
  CommonTableRow,
  CommonEllipsisDropdown,
  CommonEllipsisContent,
} from './CommonStyles';

const SummaryCard = styled(CommonContainer)`
  caret-color: transparent;
  background-color: #f2f2f2;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  caret-color: transparent;
`;
const EllipsisButton = styled.button`
  background-color: #4caf50;
  color: white; 
  border: none;
  cursor: pointer;
  padding: 5px; 
  border-radius: 4px;
`;

const EllipsisContent = styled(CommonEllipsisContent)``;

const EllipsisItem = styled.a`
  padding: 12px;
  text-decoration: none;
  display: block;
  color: black;

  &:hover {
    background-color: #ddd;
  }
`;

const ChartContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 20px 0;
  flex-wrap: wrap;

  @media (max-width: 768px) { /* Adjust this value based on your specific breakpoint needs */
    flex-direction: column;
  }
`;

const TableCell = styled(CommonTableCell)`
  ${props => props.$isPaid && css`
    background-color: #e8f5e9;
  `}

  ${props => props.$isTemp && css`
    background-color: #fffde7;
  `}
`;

const Table = styled(CommonTable)`
  border-collapse: collapse;
  margin-top: 10px;
  width: auto;
  min-width: 100%;
`;

const TableContainer = styled.div`
  caret-color: transparent;
  margin-top: 20px;
  overflow-x: auto;
  z-index: 0;
  display: block;
  max-width: 100%;
`;

const formatDollarValue = (value) => new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);

const getPayPeriod = () => {
  const currentDate = new Date();
  const currentDay = currentDate.getDate();

  if (currentDay <= 16) {
    return 1;
  } else {
    return 2;
  }
};

const BillsList = () => {
  const [bills, setBills] = useState([]);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [sortField, setSortField] = useState('dueDate'); // default sorting by dueDate
  const [sortOrder, setSortOrder] = useState('asc'); // default sorting order

  const handleSort = (field) => {
    const isAsc = sortField === field && sortOrder === 'asc';
    setSortField(field);
    setSortOrder(isAsc ? 'desc' : 'asc');
  };

  const fetchData = async () => {
    try {
      const billsCollection = collection(firestore, 'bills');
      const billsQuery = query(
        billsCollection,
        where('status', '==', 'active'), // Exclude non-active rows
        orderBy('dueDate', 'asc') // Sort by dueDate in ascending order
      );

      const billsSnapshot = await getDocs(billsQuery);

      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      const currentMonth = currentDate.getMonth(); // Note: months are 0-indexed in JavaScript Date objects

      const billsData = billsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      })).filter(bill => {
        const billDueDate = new Date(bill.dueDate);
        return billDueDate.getFullYear() === currentYear && billDueDate.getMonth() === currentMonth;
      });

      setBills(billsData);
    } catch (error) {
      console.error('Error fetching bills:', error);
    }
  };

  useEffect(() => {
    // Fetch initial bills data from Firebase
    fetchData();
  }, []);

  const markBillAsPaid = async (billId) => {
    const billRef = doc(firestore, 'bills', billId);
    await updateDoc(billRef, {
      paid: true,
    });

    // Refresh the bills data after marking as paid
    fetchData();
  };

  // Calculate the sums for bills due, paid, and not paid
  const totalAmountDue = formatDollarValue(
    bills.reduce((total, bill) => (total + parseFloat(bill.amount)), 0)
  );
  const totalAmountPaid = formatDollarValue(
    bills.reduce((total, bill) => (bill.paid ? total + parseFloat(bill.amount) : total), 0)
  );
  const totalAmountNotPaid = formatDollarValue(
    bills.reduce((total, bill) => (!bill.paid ? total + parseFloat(bill.amount) : total), 0)
  );
  const totalTemporaryBills = formatDollarValue(
    bills.reduce((total, bill) => (bill.temp ? total + parseFloat(bill.amount) : total), 0)
  );

  const payPeriod = getPayPeriod();

  // Calculate the total left to pay for the current pay period
  const totalLeftToPayThisPeriod = formatDollarValue(
    bills.reduce((total, bill) => {
      if (!bill.paid) {
        const billDueDate = new Date(bill.dueDate);
        const billPayPeriod = billDueDate.getDate() <= 16 ? 1 : 2;

        if (billPayPeriod === payPeriod || (payPeriod === 2 && billPayPeriod === 1)) {
          return total + parseFloat(bill.amount);
        }
      }
      return total;
    }, 0)
  );

  const deleteBill = async (id) => {
    await deleteDoc(doc(firestore, 'bills', id));
    setBills(bills.filter(bill => bill.id !== id));
  };

  const getComparable = (value, field) => {
    if (field === 'amount') {
      // Assuming dollar values are stored as strings, remove any non-numeric characters (e.g., $) before converting to number
      return Number(value.replace(/[^0-9.-]+/g, ""));
    } else if (field === 'dueDate') {
      // Convert string dates to Date objects for comparison
      return new Date(value);
    } else {
      // Treat as string for any other type of data
      return value.toString().toLowerCase();
    }
  };

  const sortedBills = bills.sort((a, b) => {
    const valueA = getComparable(a[sortField], sortField);
    const valueB = getComparable(b[sortField], sortField);

    if (valueA < valueB) return sortOrder === 'asc' ? -1 : 1;
    if (valueA > valueB) return sortOrder === 'asc' ? 1 : -1;
    return 0;
  });

  const pieChartData = [
    { name: "Total Outstanding", value: parseFloat(totalAmountNotPaid.replace(/\$/g, '').replace(/,/g, '')) },
    { name: "Total Amount Paid", value: parseFloat(totalAmountPaid.replace(/\$/g, '').replace(/,/g, '')) },
  ];

  const periodPieChartData = [
    { name: "Paid This Period", value: parseFloat(totalAmountPaid.replace(/\$/g, '').replace(/,/g, '')) }, // Assuming totalAmountPaid is already calculated for the period
    { name: "Left to Pay This Period", value: parseFloat(totalLeftToPayThisPeriod.replace(/\$/g, '').replace(/,/g, '')) },
  ];

  return (
    <CommonContainer>
      <CommonTitle>Bills List</CommonTitle>

      {/* Summary Card */}
      <SummaryCard>
        <CommonTitle>Financial Summary</CommonTitle>
        <CommonSummaryText>Total Monthly Due: {totalAmountDue}</CommonSummaryText>
        <CommonSummaryText>Total Amount Paid: {totalAmountPaid}</CommonSummaryText>
        <CommonSummaryText>Total Outstanding: {totalAmountNotPaid}</CommonSummaryText>
        <CommonSummaryText>Remaining This Pay Period: {totalLeftToPayThisPeriod}</CommonSummaryText>
        <CommonSummaryText>Total Temporary Bills: {totalTemporaryBills}</CommonSummaryText>
      </SummaryCard>

      <ChartContainer>
        {payPeriod === 1 && (
          <PieChart width={400} height={400}>
            <Pie
              data={periodPieChartData}
              cx={200}
              cy={200}
              outerRadius={100}
              fill="#8884d8"
              dataKey="value"
              label={(entry) => `${entry.value}`}
            >
              <Cell key={`cell-0`} fill="#42A5F5" /> {/* Color for Paid This Period */}
              <Cell key={`cell-1`} fill="#EF5350" /> {/* Color for Left to Pay This Period */}
            </Pie>
            <Tooltip />
            <Legend />
          </PieChart>
        )}
        <PieChart width={400} height={400}>
          <Pie
            data={pieChartData}
            cx={200}
            cy={200}
            outerRadius={100}
            fill="#8884d8"
            dataKey="value"
            label
          >
            <Cell key={`cell-0`} fill="#8884d8" />
            <Cell key={`cell-1`} fill="#82ca9d" />
          </Pie>
          <Tooltip />
          <Legend />
        </PieChart>
      </ChartContainer>
      <TableContainer>
        <Table>
          <thead>
            <tr>
              <CommonTableHeader onClick={() => handleSort('title')}>Title</CommonTableHeader>
              <CommonTableHeader onClick={() => handleSort('amount')}>Amount</CommonTableHeader>
              <CommonTableHeader onClick={() => handleSort('dueDate')}>Due Date</CommonTableHeader>
              <CommonTableHeader>Action</CommonTableHeader>
            </tr>
          </thead>
          <tbody>
            {sortedBills.map(bill => (
              <CommonTableRow key={bill.id}>
                <TableCell $isTemp={bill.temp}>{bill.title}</TableCell>
                <TableCell>{formatDollarValue(parseFloat(bill.amount))}</TableCell>
                <TableCell $isPaid={bill.paid}>{bill.dueDate}</TableCell>
                <TableCell>
                  <CommonEllipsisDropdown onMouseEnter={() => setIsDropdownVisible(true)} onMouseLeave={() => setIsDropdownVisible(false)}>
                    <EllipsisButton>...</EllipsisButton>
                    {isDropdownVisible && (<EllipsisContent>
                      <EllipsisItem onClick={() => deleteBill(bill.id)}>Delete</EllipsisItem>
                      {!bill.paid && (<EllipsisItem onClick={() => markBillAsPaid(bill.id)}>Paid</EllipsisItem>)}
                    </EllipsisContent>
                    )}
                  </CommonEllipsisDropdown>
                </TableCell>
              </CommonTableRow>
            ))}
          </tbody>
        </Table>
      </TableContainer>
      <CommonBottomBar>
        <div>Remaining: {totalLeftToPayThisPeriod} | Temporary Bills: {totalTemporaryBills}</div>
      </CommonBottomBar>
    </CommonContainer>
  );

};

export default BillsList;
