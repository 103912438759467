import React, { useState, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import {
  collection,
  getDocs,
  query,
  where,
} from 'firebase/firestore';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import NavLinks from './NavLinks'; // Import the NavLinks component
import { firestore } from './firebase';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  font-family: 'Arial', sans-serif;
`;

const NavbarContainer = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: linear-gradient(to right, #4D9FEC, #00B4DB);
  color: white;
  padding: 15px 20px;
  display: flex;
  align-items: center;
  z-index: 2;
  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
`;

const LinksProfileContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: start; // Align items to the start of the flex container
`;

const Logo = styled.h1`
  margin: 0;
  font-size: 1.5em;
  text-align: center;
  flex: 1;
  caret-color: transparent;
  display: inline-block;
  cursor: default;
`;

const Hamburger = styled.div`
  position: relative; // Ensure it's positioned correctly
  z-index: 4;
  caret-color: transparent;
  display: inline-block;
  cursor: pointer;

  div {
    width: 25px;
    height: 3px;
    background-color: white;
    margin: 5px 0;
  }
`;

const Backdrop = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 2; // Behind the drawer but above other content
  display: ${props => props.$show ? "block" : "none"};
`;

const Content = styled.div`
  flex: 1;
  padding: 20px;
`;

const UserProfile = styled.div`
  margin-left: auto;
`;

const LayoutContainer = ({ children, user, onSignOut }) => {
  const [isopen, setisopen] = useState(false);
  const location = useLocation();
  const [page, setPage] = useState(0); // This state could be updated on page changes
  const isAuthenticated = user !== null;

  const [currentTimeBlock, setCurrentTimeBlock] = useState('');

  const currentDay = new Date().toLocaleDateString('en-US', { weekday: 'long' });  // e.g., "Monday"
  const currentTime = useMemo(() => {
    const time = new Date();
    time.setMinutes(time.getMinutes() - (time.getMinutes() % 30));
    return time;
  }, []);
  const roundedTime = currentTime.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });

  useEffect(() => {
    setPage(prevPage => prevPage + 1);
  }, [location]);

  useEffect(() => {
    if (user) {
      const username = user.email.includes('jeremiah') ? 'Jeremiah' : 'Kendra';
      const timeBlocksRef = collection(firestore, 'user-time-blocks');
      const q = query(timeBlocksRef, where('user', '==', username), where('dow', '==', currentDay), where('time', '==', roundedTime));
      getDocs(q).then(querySnapshot => {
        const currentBlock = querySnapshot.docs.map(doc => doc.data().name);
        if (currentBlock.length > 0) {
          setCurrentTimeBlock(currentBlock[0]);
        }
      });
    }
  }, [user, currentDay, roundedTime, page]);

  const handleToggleMenu = () => {
    setisopen(!isopen);
  };

  return (
    <Container>
      {isopen && <Backdrop $show={isopen} onClick={handleToggleMenu} />}
      <NavbarContainer>
        <Hamburger onClick={handleToggleMenu}>
          <div />
          <div />
          <div />
        </Hamburger>
        <Logo>{`Activity - ${currentTimeBlock || 'Loading...'}`}</Logo>
        <LinksProfileContainer>
          <NavLinks isopen={isopen} onSignOut={onSignOut} setisopen={setisopen} />
          {isAuthenticated && <UserProfile>{user.displayName}</UserProfile>}
        </LinksProfileContainer>
      </NavbarContainer>
      {isAuthenticated ? (
        <Content>{children}</Content>
      ) : (
        <Content>
          <p>Please sign in to access the content.</p>
        </Content>
      )}
    </Container>
  );
};

LayoutContainer.propTypes = {
  children: PropTypes.node,
  user: PropTypes.object,
  isopen: PropTypes.bool,
  onSignOut: PropTypes.func,
};

export default LayoutContainer;
