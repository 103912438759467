// firebase.js
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth'; // Import Auth functions

const firebaseConfig = {
    apiKey: "AIzaSyCeERffWzX7exwLqwEmLV6ewDOI89FRpNQ",
    authDomain: "chores-7bdc9.firebaseapp.com",
    databaseURL: "https://chores-7bdc9-default-rtdb.firebaseio.com",
    projectId: "chores-7bdc9",
    storageBucket: "chores-7bdc9.appspot.com",
    messagingSenderId: "1002305526635",
    appId: "1:1002305526635:web:b2d467c910ace24b3a8a2e"
};

const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);
const auth = getAuth(app); // Initialize Firebase Authentication

export { firestore, auth };
