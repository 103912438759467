import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import {
  CommonContainer,
  CommonTitle,
  CommonTable,
  CommonTableRow,
  CommonTableHeader,
  CommonTableCell,
} from './CommonStyles';
import { collection, getDocs, setDoc, doc, query, where } from 'firebase/firestore';
import { firestore } from '../firebase';

const localizer = momentLocalizer(moment);

const TabContainer = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

const Tab = styled.button`
  padding: 10px 20px;
  cursor: pointer;
  opacity: 0.6;
  background: white;
  border: none;
  outline: none;
  ${({ $active }) => $active === "true" && `
    border-bottom: 2px solid black;
    opacity: 1;
  `}
`;

const ContentContainer = styled.div`
  padding: 20px;
  border: 1px solid #ccc;
  height: ${props => props.$tall ? '800px' : 'auto'}; /* Adjust the height value as needed */
  overflow-y: auto; /* Add this line to enable vertical scrolling when the content exceeds the container height */

  ${props => props.$tall && css`
    /* Add any additional styles for the tall container here */
  `}
`;

const StyledSelect = styled.select`
  width: 100%;
  padding: 5px;
  font-size: 16px;
  margin-top: 5px;
`;

const DaySelector = styled(StyledSelect)`
  margin-bottom: 10px;
`;

const generateTimeSlots = () => {
  const slots = [];
  for (let hour = 0; hour < 24; hour++) {
    const period = hour < 12 ? 'AM' : 'PM';
    const formattedHour = hour % 12 === 0 ? 12 : hour % 12;
    for (let minute = 0; minute < 60; minute += 30) {
      const timeString = `${formattedHour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')} ${period}`;
      slots.push(timeString);
    }
  }
  return slots;
};

const DailyHours = () => {
  const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const [weeklyMetrics, setWeeklyMetrics] = useState({});
  const todayIndex = new Date().getDay(); // Gets today's day index (0-6)
  const todayDay = daysOfWeek[todayIndex]; // Maps index to day name
  const [events, setEvents] = useState([]);
  const [activeTab, setActiveTab] = useState(2);
  const [selectedDay, setSelectedDay] = useState(todayDay); // Set initial state to today
  const [timeBlocks, setTimeBlocks] = useState([]);
  const [userTimeBlocks, setUserTimeBlocks] = useState({ Kendra: {}, Jeremiah: {} });

  const timeSlots = generateTimeSlots();

  const eventColors = {
    sleep: 'purple',
    tz: 'red',
    carter: 'green',
    fun: 'blue',
    chores: 'orange',
    church: 'pink',
  };

  useEffect(() => {
    const fetchWeeklyEvents = async () => {
      const users = ['Kendra', 'Jeremiah'];
      const weeklyEvents = [];

      for (const user of users) {
        for (const day of daysOfWeek) {
          const userQuery = query(
            collection(firestore, 'user-time-blocks'),
            where('user', '==', user),
            where('dow', '==', day)
          );
          const querySnapshot = await getDocs(userQuery);
          querySnapshot.forEach(doc => {
            const { name, time, dow } = doc.data();
            const start = moment(`${dow} ${time}`, 'dddd hh:mmA').toDate();
            const end = moment(start).add(30, 'minutes').toDate();
            const eventColor = eventColors[name.toLowerCase()] || '#000000'; // Default to black if no color is defined
            weeklyEvents.push({
              title: `${user}: ${name}`,
              start,
              end,
              color: eventColor,
            });
          });
        }
      }

      setEvents(weeklyEvents);
    };

    fetchWeeklyEvents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchWeeklyMetrics = async () => {
      const users = ['Kendra', 'Jeremiah'];
      const metrics = {};

      for (const user of users) {
        metrics[user] = {};

        for (const day of daysOfWeek) {
          const userQuery = query(
            collection(firestore, 'user-time-blocks'),
            where('user', '==', user),
            where('dow', '==', day)
          );
          const querySnapshot = await getDocs(userQuery);
          querySnapshot.forEach(doc => {
            const category = doc.data().name;
            if (!metrics[user][category]) {
              metrics[user][category] = 0.5; // Assuming each block is 30 minutes
            } else {
              metrics[user][category] += 0.5;
            }
          });
        }
      }

      setWeeklyMetrics(metrics);
    };

    fetchWeeklyMetrics();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchTimeBlocks = async () => {
      const querySnapshot = await getDocs(collection(firestore, 'time-blocks'));
      const blocks = querySnapshot.docs.map(doc => doc.data().name);
      setTimeBlocks(blocks);
    };

    fetchTimeBlocks();
  }, []);

  useEffect(() => {
    const fetchUserTimeBlocks = async () => {
      const users = ['Kendra', 'Jeremiah'];
      const blocks = {};

      await Promise.all(users.map(async (user) => {
        const userQuery = query(
          collection(firestore, 'user-time-blocks'),
          where('user', '==', user),
          where('dow', '==', selectedDay)
        );
        const querySnapshot = await getDocs(userQuery);
        blocks[user] = {};
        querySnapshot.forEach(doc => {
          blocks[user][doc.data().time] = doc.data().name;
        });
      }));

      setUserTimeBlocks(blocks);
    };

    fetchUserTimeBlocks();
  }, [selectedDay]);

  const handleSelectChange = async (user, time, event) => {
    const name = event.target.value;
    const newUserTimeBlock = {
      user,
      time,
      name,
      dow: selectedDay
    };

    await setDoc(doc(firestore, 'user-time-blocks', `${user}-${selectedDay}-${time}`), newUserTimeBlock);
    setUserTimeBlocks(prev => ({
      ...prev,
      [user]: { ...prev[user], [time]: name }
    }));
  };

  const mergeCategories = (metrics) => {
    const mergedMetrics = {};
    Object.entries(metrics).forEach(([category, hours]) => {
      if (category === 'TZ' || category === 'Church') {
        const workHours = (mergedMetrics['Work'] || 0) + hours;
        mergedMetrics['Work'] = workHours;
      } else {
        mergedMetrics[category] = hours + (mergedMetrics[category] || 0);
      }
    });
    return mergedMetrics;
  };

  const renderTable = (user) => (
    <CommonTable>
      <thead>
        <CommonTableRow>
          <CommonTableHeader>Time</CommonTableHeader>
          <CommonTableHeader></CommonTableHeader>
        </CommonTableRow>
      </thead>
      <tbody>
        {timeSlots.map(time => (
          <CommonTableRow key={`${user}-${time}`}>
            <CommonTableCell>{time}</CommonTableCell>
            <CommonTableCell>
              <StyledSelect value={userTimeBlocks[user][time] || ''} onChange={(e) => handleSelectChange(user, time, e)}>
                <option value="">Select Time Block</option>
                {timeBlocks.map(block => (
                  <option key={block} value={block}>{block}</option>
                ))}
              </StyledSelect>
            </CommonTableCell>
          </CommonTableRow>
        ))}
      </tbody>
    </CommonTable>
  );

  const renderWeeklyMetricsComparison = () => {
    const users = ['Kendra', 'Jeremiah'];
    const allCategories = new Set();
    const userMetrics = {};

    // Collect and merge data
    users.forEach(user => {
      const mergedMetrics = mergeCategories(weeklyMetrics[user] || {});
      userMetrics[user] = mergedMetrics;
      Object.keys(mergedMetrics).forEach(category => allCategories.add(category));
    });

    // Render table with merged categories
    return (
      <div>
        <CommonTitle>Weekly Metrics Comparison</CommonTitle>
        <CommonTable>
          <thead>
            <CommonTableRow>
              <CommonTableHeader>Category</CommonTableHeader>
              {users.map(user => (
                <CommonTableHeader key={user}>{user}</CommonTableHeader>
              ))}
            </CommonTableRow>
          </thead>
          <tbody>
            {[...allCategories].map(category => (
              <CommonTableRow key={category}>
                <CommonTableCell>{category}</CommonTableCell>
                {users.map(user => {
                  // Determine if the current user has the max hours for this category
                  let isMaxForCategory = true; // Assume current user has max hours
                  users.forEach(otherUser => {
                    if ((userMetrics[otherUser][category] || 0) > (userMetrics[user][category] || 0)) {
                      isMaxForCategory = false; // Found another user with more hours
                    }
                  });

                  return (
                    <CommonTableCell key={user} style={{ fontWeight: isMaxForCategory ? 'bold' : 'normal' }}>
                      {`${userMetrics[user][category] || 0} hours`}
                    </CommonTableCell>
                  );
                })}
              </CommonTableRow>
            ))}
          </tbody>
        </CommonTable>
      </div>
    );
  };


  const renderTodaysMetrics = () => {
    return (
      <div>
        {renderUserMetrics(userTimeBlocks['Kendra'], 'Kendra')}
        {renderUserMetrics(userTimeBlocks['Jeremiah'], 'Jeremiah')}
      </div>
    );
  };

  const renderUserMetrics = (userTimeBlocks, user) => {
    const categorySummary = {};

    Object.entries(userTimeBlocks).forEach(([time, category]) => {
      if (!categorySummary[category]) {
        categorySummary[category] = 0.5; // Assuming each block is 30 minutes
      } else {
        categorySummary[category] += 0.5;
      }
    });

    const summaryElements = Object.entries(categorySummary).map(([category, duration]) => (
      <CommonTableRow key={category}>
        <CommonTableCell>{category}</CommonTableCell>
        <CommonTableCell>{`${duration} hours`}</CommonTableCell>
      </CommonTableRow>
    ));

    return (
      <div>
        <CommonTitle>{user}s Time Spent</CommonTitle>
        <CommonTable>
          <thead>
            <CommonTableRow>
              <CommonTableHeader>Category</CommonTableHeader>
              <CommonTableHeader>Total Time Spent</CommonTableHeader>
            </CommonTableRow>
          </thead>
          <tbody>
            {summaryElements}
          </tbody>
        </CommonTable>
      </div>
    );
  };

  return (
    <CommonContainer>
      <CommonTitle>Daily Hours</CommonTitle>
      <DaySelector value={selectedDay} onChange={e => setSelectedDay(e.target.value)} disabled={activeTab === 3}>
        {daysOfWeek.map(day => (
          <option key={day} value={day}>{day}</option>
        ))}
      </DaySelector>
      <TabContainer>
        <Tab $active={activeTab === 2 ? "true" : "false"} onClick={() => setActiveTab(2)}>Todays Metrics</Tab>
        <Tab $active={activeTab === 3 ? "true" : "false"} onClick={() => setActiveTab(3)}>Weekly Metrics</Tab>
        <Tab $active={activeTab === 0 ? "true" : "false"} onClick={() => setActiveTab(0)}>Kendra</Tab>
        <Tab $active={activeTab === 1 ? "true" : "false"} onClick={() => setActiveTab(1)}>Jeremiah</Tab>
        <Tab $active={activeTab === 4 ? "true" : "false"} onClick={() => setActiveTab(4)}>Week Calendar</Tab>
      </TabContainer>

      <ContentContainer>
        {activeTab === 0 && renderTable('Kendra')}
        {activeTab === 1 && renderTable('Jeremiah')}
        {activeTab === 2 && renderTodaysMetrics()}
        {activeTab === 3 && renderWeeklyMetricsComparison()}
        {activeTab === 4 && (
          <Calendar
            localizer={localizer}
            events={events}
            startAccessor="start"
            endAccessor="end"
            defaultView="week"
            views={['week']}
            step={30}
            timeslots={2}
            style={{ height: 1200 }}
            eventPropGetter={(event) => ({
              style: {
                backgroundColor: event.color,
              },
            })}
          />
        )}
      </ContentContainer>
      <ContentContainer>
      </ContentContainer>
    </CommonContainer>
  );
};

export default DailyHours;
