/* eslint-disable react/prop-types */
// BudgetSummary.js
import React, { useEffect, useState } from 'react';
import { firestore } from '../firebase';
import { collection, getDocs, query, where, updateDoc, doc } from 'firebase/firestore';
import { useMediaQuery } from 'react-responsive';
import { CommonContainer, CommonTitle, CommonButton, CommonBottomBar } from './CommonStyles';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, LabelList, Tooltip, Legend, ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';

const BudgetSummary = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 }); // Check if the device is mobile
  const [transactions, setTransactions] = useState([]);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth());
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [isNextMonthDisabled, setIsNextMonthDisabled] = useState(false);
  const [totalSpentThisMonth, setTotalSpentThisMonth] = useState(0);
  const [totalBudgetedThisMonth, setTotalBudgetedThisMonth] = useState(0);
  const income = 800 + 3375 * 2 + 500; // Hardcoded income for now

  const topCategoriesToShow = 12;

  const fetchData = async () => {
    try {
      // Query for transactions in the current month
      const transactionsQuery = query(
        collection(firestore, 'transactions'),
        where('date', '>=', new Date(currentYear, currentMonth, 1)),
        where('date', '<=', new Date(currentYear, currentMonth + 1, 0))
      );

      const transactionsSnapshot = await getDocs(transactionsQuery);
      const transactionsData = transactionsSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
      setTransactions(transactionsData);

      const categoriesQuery = collection(firestore, 'categories');
      const categoriesSnapshot = await getDocs(categoriesQuery);
      const categoriesData = categoriesSnapshot.docs.map(doc => doc.data());
      setCategories(categoriesData);
    } catch (error) {
      console.error('Error fetching data:', error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const calculateTotalSpentAndBudgeted = () => {
      let totalSpent = 0;
      let totalBudgeted = 0;

      transactions.forEach((transaction) => {
        const amount = parseFloat(transaction.amount);
        totalSpent += amount;
      });

      categories.forEach((category) => {
        totalBudgeted += category.amount;
      });

      setTotalSpentThisMonth(totalSpent);
      setTotalBudgetedThisMonth(totalBudgeted);
    };

    calculateTotalSpentAndBudgeted();
  }, [transactions, categories]);

  useEffect(() => {
    fetchData();

    const today = new Date();
    const disableNext = currentYear > today.getFullYear() || (currentYear === today.getFullYear() && currentMonth >= today.getMonth());
    setIsNextMonthDisabled(disableNext);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentMonth, currentYear]);

  const handlePrevMonth = () => {
    const newMonth = currentMonth === 0 ? 11 : currentMonth - 1;
    const newYear = currentMonth === 0 ? currentYear - 1 : currentYear;
    setCurrentMonth(newMonth);
    setCurrentYear(newYear);
  };

  const handleNextMonth = () => {
    const today = new Date();
    if (currentYear < today.getFullYear() || (currentYear === today.getFullYear() && currentMonth < today.getMonth())) {
      const newMonth = currentMonth === 11 ? 0 : currentMonth + 1;
      const newYear = currentMonth === 11 ? currentYear + 1 : currentYear;
      setCurrentMonth(newMonth);
      setCurrentYear(newYear);
    }
  };
  const calculateCategorySum = () => {
    const categorySum = {};

    transactions.forEach((transaction) => {
      const category = transaction.category || 'Uncategorized';
      if (category !== 'Uncategorized') { // Exclude Uncategorized transactions
        const amount = parseFloat(transaction.amount);
        categorySum[category] = (categorySum[category] || 0) + amount; // Total spent in the category
      }
    });

    const sortedCategorySum = Object.entries(categorySum)
      .sort((a, b) => b[1] - a[1])
      .map(([category, sum]) => ({
        category,
        sum: parseFloat(sum.toFixed(2)),
      }));

    const otherCategorySum = sortedCategorySum
      .slice(topCategoriesToShow)
      .reduce((acc, curr) => acc + curr.sum, 0);

    const topCategories = sortedCategorySum
      .slice(0, topCategoriesToShow)
      .concat({ category: 'Other', sum: parseFloat(otherCategorySum).toFixed(2) });

    return topCategories.filter(item => item.sum !== 0 && item.sum !== '0.00');
  };

  const categorySumData = calculateCategorySum();

  const calculateCompletionData = () => {
    let categorySum = {};
    let categoryCaps = {};
    let categoryColors = {};
    categories.forEach(category => {
      categoryCaps[category.name] = category.amount; // Budget cap for the category
      categoryColors[category.name] = category.color; // Color for the category
    });

    transactions.forEach(transaction => {
      const category = transaction.category || 'Uncategorized';
      if (category !== 'Uncategorized') {
        const amount = parseFloat(transaction.amount);
        categorySum[category] = (categorySum[category] || 0) + amount; // Total spent in the category
      }
    });

    let data = Object.entries(categorySum).map(([name, amount]) => ({
      name,
      amount,
      Percentage: Math.min(((amount / (categoryCaps[name] || 1)) * 100).toFixed(0), 100), // Cap at 100%
      cap: categoryCaps[name],
      color: categoryColors[name] || '#8884d8', // Default color if not specified
    }));

    // Custom sorting logic: $0 items first, then by Percentage in descending order
    data.sort((a, b) => {
      if (a.cap === 0 && b.cap !== 0) {
        return -1;
      } else if (a.cap !== 0 && b.cap === 0) {
        return 1;
      } else {
        return b.Percentage - a.Percentage;
      }
    });

    return data;

  };

  const data = (calculateCompletionData()).filter(item => item.Percentage !== 0);

  function getContrastingColor(rgba) {
    // Extract the RGB values from the rgba string
    const [r, g, b] = rgba.match(/\d+/g).map(Number);

    // Calculate the luminance
    const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

    // Return black or white depending on the luminance
    return luminance > 0.5 ? 'black' : 'white';
  }

  const renderCustomLabel = (props) => {
    const { x, y, width, value, color, data, index } = props;
    const barColor = color;
    const labelColor = width / 400 >= 0.6 ? getContrastingColor(barColor) : '#000';

    const newY = value > 0 ? y + 20 : y + 25;
    const newX = x + 20;

    const amountOverUnder = value - data[index].cap;
    const overUnderPercentage = Math.abs(amountOverUnder / data[index].cap * 100);

    if (isMobile) {
      // Simplified label for mobile devices
      const overUnderText =
        overUnderPercentage >= 5
          ? amountOverUnder > 0
            ? `⬆️$${amountOverUnder.toFixed(2)}`
            : `⬇️$${(-amountOverUnder).toFixed(2)}`
          : '';

      return (
        <text x={newX} y={newY} fill={labelColor} textAnchor="start" dominantBaseline="central">
          {value <= data[index].cap ? '✅' : '❌'} ${parseFloat(data[index].cap).toFixed(2)} {overUnderText}
        </text>
      );
    }

    // Existing label for desktop devices
    const overUnderText =
      overUnderPercentage >= 5
        ? amountOverUnder > 0
          ? `⬆️$${amountOverUnder.toFixed(2)}`
          : `⬇️$${(-amountOverUnder).toFixed(2)}`
        : '';

    return (
      <text x={newX} y={newY} fill={labelColor} textAnchor="start" dominantBaseline="central">
        {value <= data[index].cap ? '✅' : '❌'} ${value.toFixed(2)} of ${parseFloat(data[index].cap).toFixed(2)} {overUnderText}
      </text>
    );
  };

  const handleBudgetEdit = async (data, index) => {
    const categoryName = data.name;
    const currentBudget = data.cap;

    const newBudget = prompt(
      `Enter new budget for ${categoryName}`,
      currentBudget
    );

    if (newBudget !== null && newBudget !== "") {
      try {
        const categoryQuery = query(
          collection(firestore, "categories"),
          where("name", "==", categoryName)
        );
        const querySnapshot = await getDocs(categoryQuery);
        console.log(querySnapshot)
        querySnapshot.forEach(async (docIn) => {
          const categoryRef = doc(firestore, "categories", docIn.id);
          await updateDoc(categoryRef, { amount: parseFloat(newBudget) });
        });

        // Optionally, you can refetch the categories and transactions data
        // to reflect the updated budget amount in the charts
        fetchData();
      } catch (error) {
        console.error("Error updating budget:", error.message);
      }
    }
  };

  return (
    <CommonContainer>
      <CommonTitle>Spending By Category</CommonTitle>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
        <CommonButton onClick={handlePrevMonth}>
          Previous Month
        </CommonButton>
        <CommonButton onClick={handleNextMonth} disabled={isNextMonthDisabled}>
          Next Month
        </CommonButton>
      </div>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <div>
          <PieChart width={400} height={400}>
            <Pie
              dataKey="sum"
              isAnimationActive={false}
              data={categorySumData}
              cx={200}
              cy={200}
              outerRadius={80}
              fill="#8884d8"
              label
            >
              {categorySumData.map((entry, index) => (
                <Cell
                  key={index}
                  fill={categories.find((category) => category.name === entry.category)?.color || `rgba(0, 0, 0, 0.8)`}
                />
              ))}
            </Pie>
            <Tooltip formatter={(value, name) => [value.toFixed(2), name]} />
            <Legend formatter={(value, entry) => entry.payload.category} />
          </PieChart>
        </div>
      )}
      {loading ? <p>Loading...</p> : (
        <ResponsiveContainer width="100%" height={800}>
          <BarChart
            width={500}
            height={300}
            data={data}
            layout="vertical"
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis type="number" />
            <YAxis dataKey="name" tick={{ fontSize: '12px' }} type="category" />
            <Tooltip />
            <Bar dataKey="Percentage"
              onClick={(data, index) => handleBudgetEdit(data, index)}
            >
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={entry.color} />
              ))}

              <LabelList dataKey="amount" content={(props) => renderCustomLabel({ ...props, data })} />
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      )}
      <CommonBottomBar>
        <div>Total Spent: ${totalSpentThisMonth.toFixed(2)} | Budgeted: ${totalBudgetedThisMonth.toFixed(2)} | Income: ${income}</div>
      </CommonBottomBar>
    </CommonContainer>
  );
};

export default BudgetSummary;
